body {
  margin: 0;
  font-family: 'Microsoft YaHei', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: #333333;
}

code {
  font-family: 'Microsoft YaHei', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary: #8d613c;
  --secondary: #bd916c;

  --success: #66B032;
  --info: #497bff;
  --infoBorderColor:#3160e2;
  --warning: #FB9902;
  --danger: #cd1300;
  --dangerBorderColor:#aa1100;

  --black: #000000;
  --white: #ffffff;

  --dark1: #999;
  --dark2: #666;
  --dark3: #333;


  --gray1: #f5f5f5;
  --gray2: #f0f0f0;
  --gray3: #eee;
  --gray4: #ddd;
  --gray5: #ccc;
  --gray6: #bbb;

  --background1:#fdfdfd;
  --background2:#fcfcfc;
  --background3:#fafafa;
  --background4:#f6f6f6;
  --background5:#f0f0f0;

  --red:#FE2712;
  --redOrange:#FC600A;
  --orange:#FB9902;
  --yellowOrange:#FCCC1A;
  --yellow:#FEFE33;
  --yellowGreen:#B2D732;
  --green:#66B032;
  --cyan:#347C98;
  --blue:#0247FE;
  --bluePurple:#4424D6;
  --purple:#8601AF;
  --purpleRed:#C21460;

  --borderColor: #eee;
  --placeholderColor:#a9a9a9;
  --background:#ddd;
  --backgroundInput:#2c1f68;

}
input{border:1px solid var(--gray5);border-radius: 5px;padding:5px;box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.2);}
input:focus {
  outline: none;
}
input::placeholder {
  color: gray;
  font-style: italic;
}
.triangletopLeft {
  width: 0;
  height: 0;
  border-top: 100px solid red;
  border-right: 100px solid transparent;
}
.triangleBottomRight {
  width: 0;
  height: 0;
  border-bottom: 100px solid red;
  border-left: 100px solid transparent;
}